<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>供应商管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewSupplier">
            新增供应商
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名称</th>
              <th scope="col">备注</th>
              <th scope="col">编辑</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sInfo in supplierInfoList" :key="sInfo.id">
              <td scope="row">{{ sInfo.id }}</td>
              <td>{{ sInfo.name }}</td>
              <td>{{ sInfo.note }}</td>
              <td>
                <a href="" @click.prevent="showModalForEditSupplier(sInfo.id)"
                  >编辑</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- modal -->
    <Modal>
      <template v-slot:modalBody>
        <form>
          <div class="form-row">
            <!-- <div class="form-group col-md-6"> -->
            <div class="form-group col-12">
              <label for="supplierName" class="float-left">名称</label>
              <input
                type="text"
                class="form-control"
                id="supplierName"
                v-model="supplierForNewOrEdit.name"
              />
            </div>
            <div class="form-group col-12">
              <label for="supplierAddress" class="float-left">地址</label>
              <input
                type="text"
                class="form-control"
                id="supplierAddress"
                v-model="supplierForNewOrEdit.address"
              />
            </div>
            <div class="form-group col-12">
              <label for="supplierContact" class="float-left">联系方式</label>
              <input
                type="text"
                class="form-control"
                id="supplierContact"
                v-model="supplierForNewOrEdit.contact"
              />
            </div>
            <div class="form-group col-12">
              <label for="supplierName" class="float-left">备注</label>
              <input
                type="text"
                class="form-control"
                id="supplierName"
                v-model="supplierForNewOrEdit.note"
              />
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footerButton>
        <button type="button" class="btn btn-primary" @click="saveSupplierInfo">
          保存
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "SupplierList",
  components: { Modal },
  setup() {
    const store = useStore();

    // for load supplier list
    const supplierInfoList = ref([]);
    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadSupplierInfoList();
      isNeedToShowSpinner.value = false;
    });

    async function loadSupplierInfoList() {
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadSupplierInfoList", {
        pageNum: 1,
        pageSize: 100000,
      });
      supplierInfoList.value = response.data.rows;
      isNeedToShowSpinner.value = false;
    }

    const supplierForNewOrEdit = ref({});
    // for add new product
    function showModalForAddNewSupplier() {
      supplierForNewOrEdit.value = {};
      store.commit("displayModal", {
        modalTitle: "添加供应商信息",
      });
    }
    // for edit product info
    function showModalForEditSupplier(id) {
      let selectedSupplier = supplierInfoList.value.filter(
        (x) => x.id == id
      )[0];

      let title = "编辑-" + selectedSupplier.name;
      supplierForNewOrEdit.value = selectedSupplier;
      store.commit("displayModal", {
        modalTitle: title,
      });
    }

    async function saveSupplierInfo() {
      if (supplierForNewOrEdit.value.id && supplierForNewOrEdit.value.id > 0) {
        // edit
        console.log("Edit");
        console.log(supplierForNewOrEdit.value);

        store.commit("hideModal");
        await store.dispatch("updateSupplierInfo", supplierForNewOrEdit.value);
        loadSupplierInfoList();
      } else {
        // add new
        if (
          supplierForNewOrEdit.value &&
          supplierForNewOrEdit.value.name &&
          supplierForNewOrEdit.value.name.trim() != ""
        ) {
          // console.log("Add new one");
          // console.log(supplierForNewOrEdit.value);
          await store.dispatch(
            "addNewSupplierInfo",
            supplierForNewOrEdit.value
          );
          store.commit("hideModal");
          loadSupplierInfoList();
        } else {
          alert("供应商名称不能为空。");
        }
      }
    }

    return {
      supplierInfoList,
      showModalForAddNewSupplier,
      showModalForEditSupplier,
      supplierForNewOrEdit,
      saveSupplierInfo,
      isNeedToShowSpinner,
    };
  },
};
</script>
