<template>
  <footer class="section-footer border-top bg">
    <div class="container">
      <section class="footer-bottom row">
        <div class="col">
          <p class="text-muted">
            <span>&#169;</span> {{ new Date().getFullYear() }} 依如丽
          </p>
        </div>
        <div class="col">
          <p class="text-muted">备案号：ABC123456</p>
        </div>
      </section>
    </div>
  </footer>
</template>
