<template>
  <section class="section-intro padding-y-sm">
    <div class="container">
      <div class="intro-banner-wrap">
        <img src="../assets/images/1.jpg" class="img-fluid rounded" />
      </div>
    </div>
    <!-- container //  -->
  </section>
</template>
