<template>
  <section class="section-content">
    <div class="container">
      <header class="section-heading">
        <h3 class="section-title">
          热门产品
        </h3>
      </header>
      <!-- sect-heading -->
      <div class="row justify-content-center">
        <div
          v-for="pvInfo in pvInfoList"
          :key="pvInfo.id"
          class="col-6 col-md-4 col-lg-3 adjust-item-in-column"
        >
          <div href="#" class="card card-product-grid">
            <!-- <div href="#" class="card card-product-grid"> -->
            <a href="#" class="img-wrap">
              <img :src="pvInfo.image_urls.split(';')[0]" />
            </a>
            <figcaption class="info-wrap">
              <a href="#" class="title">{{ pvInfo.name }}</a>

              <div v-if="pvInfo.recommendation_rate">
                <div class="rating-wrap">
                  <ul class="rating-stars">
                    <!-- <li style="width:80%" class="stars-active"> -->
                    <li
                      :style="{
                        width: (pvInfo.recommendation_rate / 5) * 100 + '%',
                      }"
                      class="stars-active"
                    >
                      <!-- width in style controls good rating percentage -->
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </li>
                    <li>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </li>
                  </ul>
                  <span class="label-rating text-muted">推荐指数</span>
                </div>
              </div>
              <div v-else>
                <br />
                <!-- for ajust space when there is no recommendation_rate -->
              </div>

              <div class="row align-items-center">
                <div class="col-6 price mt-1">¥{{ pvInfo.price_cny }}</div>
                <!-- price-wrap.// -->
                <div class="col-3"></div>
                <div
                  class="col-3 w-100 "
                  data-toggle="modal"
                  data-target="#wechatModal"
                >
                  <img
                    class="new-media-icon"
                    src="../assets/images/wechat-logo.jpg"
                    alt=""
                  />
                </div>
              </div>
            </figcaption>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="wechatModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">期待您的光临</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img
              src="../assets/images/wechat-qr-code.jpg"
              alt=""
              style="width:9rem;height:9rem;"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "MainProductList",
  setup() {
    const store = useStore();

    // for load pv info list
    const pvInfoList = ref([]);
    async function loadPVInfoList() {
      let response = await store.dispatch("loadPVInfoListForPublic", {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      });
      totalItemNum.value = response.data.totalCount;
      pvInfoList.value = response.data;
      console.log("==========", response.data);
      console.log("==========", pvInfoList.value);
    }

    // for pagination
    const pageNum = ref(1);
    const pageSize = ref(20);
    const totalItemNum = ref(0);

    onMounted(async () => {
      await loadPVInfoList();
    });

    return { pvInfoList };
  },
};
</script>

<style scoped>
output {
  display: block;
  width: 100%;
}

/* for wechat icon */
.new-media-icon {
  float: right;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.section-content {
  border-top: 7px solid #eee;
}

.card-product-list:last-child {
  margin-bottom: 0 !important;
}

.section-content .padding-y {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.section-header,
.section-footer {
  box-shadow: 0 2px 10px rgba(51, 51, 51, 0.1);
}

/* GRID STYLE PRODUCT ITEM */
.card-product-grid {
  /* margin-bottom: 20px; */
  margin-bottom: 1rem;
  width: 14rem;
}
.card-product-grid .img-wrap {
  border-radius: 0.2rem 0.2rem 0 0;
  height: 220px;
  /* border-radius: 2px; */
}
.card-product-grid .info-wrap {
  /* overflow: hidden;
  padding: 18px 20px; */

  overflow: hidden;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
}
.card-product-grid .bottom-wrap {
  /* padding: 18px; */
  border-top: 1px solid #e4e4e4;
  padding: 1rem;
  /* border-top: 1px solid; */
}
.card-product-grid .topbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  left: 0;
  text-align: initial;
  padding: 10px 15px;
}
.card-product-grid .topbar a {
  color: #ccc;
}
.card-product-grid .topbar a:hover {
  color: #3167eb;
}

/* .card-product-grid .topbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  left: 0;
  text-align: initial;
  padding: 1rem 1.5rem;
} */

.card-product-grid .fix-height {
  height: 80px;
  overflow: hidden;
}
.card-product-grid .btn-overlay {
  transition: 0.5s;
  opacity: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  width: 100%;
  padding: 5px 0;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
.card-product-grid:hover .btn-overlay {
  opacity: 1;
}
.card-product-grid:hover {
  box-shadow: 0 4px 15px rgba(153, 153, 153, 0.3);
  transition: 0.3s;
}

.item-slide .card-product-grid {
  margin-bottom: 0;
}

.adjust-item-in-column {
  align-items: center;
}

/* for small size screen */
@media only screen and (max-width: 450px) {
  .card-product-grid {
    margin-bottom: 1rem;
    /* width: 12rem; */
    width: 12rem;
    float: left;
  }

  .adjust-item-in-column {
    padding-left: 0.2rem;
    align-items: center;
  }

  .card-product-grid .info-wrap {
    overflow: hidden;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.5rem;
    padding-right: 0.8rem;
  }

  .card-product-grid .bottom-wrap {
    padding: 1rem;
    border-top: 1px solid;
  }
}
</style>
