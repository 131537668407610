<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>类目管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewCategory">
            新增类目
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">类目名称</th>
              <th scope="col">类目等级</th>
              <th scope="col">编辑</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cInfo in categoryInfoList" :key="cInfo.id">
              <td scope="row">{{ cInfo.id }}</td>
              <td>{{ cInfo.name }}</td>
              <td>{{ cInfo.category_level }}</td>
              <td>
                <a href="" @click.prevent="showModalForEditCategory(cInfo.id)"
                  >编辑</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- modal -->
    <Modal>
      <template v-slot:modalBody>
        <form>
          <div class="form-row">
            <div class="form-group" style="width:100%">
              <label for="categoryLevel" class="float-left"
                >请选择父类（不选视为一级类目）</label
              >
              <select
                :disabled="categoryForNewOrEdit.id"
                class="form-control"
                id="categoryLevel"
                v-model="categoryForNewOrEdit.parent_level"
              >
                <option :value="-1"></option>
                <option
                  v-for="category in categoryInfoList"
                  :key="category.id"
                  :value="category.category_level"
                >
                  {{ category.category_level }} 级类目 - {{ category.name }}
                </option>
              </select>
            </div>
            <!-- <div class="form-group col-md-6"> -->
            <div class="form-group col-12">
              <label for="categoryName" class="float-left">类目名称</label>
              <input
                type="text"
                class="form-control"
                id="categoryName"
                v-model="categoryForNewOrEdit.name"
              />
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footerButton>
        <button type="button" class="btn btn-primary" @click="saveCategoryInfo">
          保存
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "CategoryList",
  components: { Modal },
  setup() {
    const store = useStore();
    // for load product list
    const categoryInfoList = ref([]);
    const categoryForNewOrEdit = ref({});

    async function loadCategoryInfoList() {
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadCategoryInfoList", {
        pageNum: 1,
        pageSize: 1000,
      });
      categoryInfoList.value = response.data.rows;
      isNeedToShowSpinner.value = false;
    }
    // for add new product
    function showModalForAddNewCategory() {
      categoryForNewOrEdit.value = {};
      store.commit("displayModal", {
        modalTitle: "添加新类目",
      });
    }
    // for edit product info
    function showModalForEditCategory(id) {
      let title =
        "编辑-" + categoryInfoList.value.filter((x) => x.id == id)[0].name;
      categoryForNewOrEdit.value = categoryInfoList.value.filter(
        (x) => x.id == id
      )[0];
      store.commit("displayModal", {
        modalTitle: title,
      });
    }

    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadCategoryInfoList();
      isNeedToShowSpinner.value = false;
    });

    // add or edit category

    async function saveCategoryInfo() {
      console.log(categoryForNewOrEdit.value);

      if (categoryForNewOrEdit.value.id && categoryForNewOrEdit.value.id > 0) {
        // edit
        console.log("Edit");
        console.log(categoryForNewOrEdit.value);
        store.commit("hideModal");
        await store.dispatch("updateCategoryInfo", categoryForNewOrEdit.value);
        loadCategoryInfoList();
      } else {
        // add new
        if (
          categoryForNewOrEdit.value ||
          categoryForNewOrEdit.value.name ||
          categoryForNewOrEdit.value.name.trim() != ""
        ) {
          console.log("Add new one");
          // console.log(supplierForNewOrEdit.value);
          await store.dispatch(
            "addNewCategoryInfo",
            categoryForNewOrEdit.value
          );
          store.commit("hideModal");
          loadCategoryInfoList();
        } else {
          alert("名称或等级不能为空。");
          return;
        }
      }
    }

    return {
      categoryInfoList,
      showModalForAddNewCategory,
      showModalForEditCategory,
      categoryForNewOrEdit,
      saveCategoryInfo,
      isNeedToShowSpinner,
    };
  },
};
</script>
