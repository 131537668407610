<template>
  <div>
    <!-- ======= Header ======= -->
    <header>
      <!-- <header id="header" class="fixed-top"> -->
      <!-- <div class="container"> -->
      <div style="padding-left:2rem; color:white; background: #2d6760;">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a href="#" class="navbar-brand logo float-left"
            ><h1 class="text-light"><span>Yirool</span></h1></a
          >
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  style="color:white; background: #2d6760;"
                  >主页</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#about"
                  style="color:white; background: #2d6760;"
                  >关于我们</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#partner"
                  style="color:white; background: #2d6760;"
                  >合作伙伴</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#contact"
                  style="color:white; background: #2d6760;"
                  >联系我们</a
                >
              </li>
            </ul>
          </div>
          <!-- collapse .// -->
        </nav>
        <!-- .nav-menu -->
      </div>
    </header>
    <!-- ======= Hero Section ======= -->
    <section id="hero">
      <div class="hero-container">
        <h1>欢迎光临依如丽服饰</h1>
        <h2>我们是一家高端品牌服饰集成代销折扣店</h2>
      </div>
    </section>
    <main id="main">
      <!-- ======= About Us Section ======= -->
      <section id="about" class="about">
        <div class="container">
          <div class="section-title">
            <h2>关于我们</h2>
          </div>

          <div class="row">
            <div class="col-lg-6 order-1 order-lg-2">
              <img
                src="../assets/portfolio/about-image.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-lg-6 order-2 order-lg-1 my-auto">
              <!-- <div class="col-lg-6 pt-lg-0 order-2 order-lg-1"> -->
              <h3>新零售&nbsp;&nbsp;新模式</h3>
              <p class="pt-5 pl-2">
                <!-- <p class="font-italic"> &nbsp;&nbsp;&nbsp;&nbsp;-->
                我们是一家高端品牌服饰集成代销折扣店，各大品牌直接供货并授权代销，零售批发于一体并配有在线电商直播销售。我们致力于让顾客感受到实实在在的物美价廉，以仓储式的价格提供专卖店的品质，给您带来看得见的经济与实惠。
              </p>

              <!-- <ul>
              <li><i class="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
              <li><i class="icofont-check-circled"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
              <li><i class="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
            </ul>
            <p>
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum
            </p> -->
            </div>
          </div>
        </div>
      </section>
      <!-- ======= Partner Section ======= -->
      <section id="partner" class="portfolio">
        <div class="container">
          <div class="section-title">
            <h2>我们的合作伙伴</h2>
          </div>
          <div class="row portfolio-container">
            <!-- partner one -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img
                  src="../assets/portfolio/cartelo.jpg"
                  class="img-fluid"
                  alt=""
                />
                <div class="portfolio-info">
                  <h4>卡帝乐鳄鱼</h4>
                  <!-- <div class="portfolio-links">
                    <a
                      href="../assets/img/portfolio/portfolio-1.jpg"
                      data-gall="portfolioGallery"
                      class="venobox"
                      title="App 1"
                      ><i class="bx bx-plus"></i
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- partner two -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img
                  src="../assets/portfolio/outdoor-products.png"
                  class="img-fluid"
                  alt=""
                />
                <div class="portfolio-info">
                  <h4>OUTDOOR PRODUCTS</h4>
                  <!-- <div class="portfolio-links">
                    <a
                      href="../assets/logo.jpg"
                      data-gall="portfolioGallery"
                      class="venobox"
                      title="App 1"
                      ><i class="bx bx-plus"></i
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- partner 3 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img
                  src="../assets/portfolio/paulo-pedro.jpg"
                  class="img-fluid"
                  alt=""
                />
                <div class="portfolio-info">
                  <h4>保罗 彼得</h4>
                </div>
              </div>
            </div>
            <!-- partner 4 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img src="../assets/portfolio/levis.jpg" class="img-fluid" />
                <div class="portfolio-info">
                  <h4>李维斯</h4>
                </div>
              </div>
            </div>
            <!-- partner 5 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img src="../assets/portfolio/leding.jpg" class="img-fluid" />
                <div class="portfolio-info">
                  <h4>乐町</h4>
                </div>
              </div>
            </div>
            <!-- partner 6 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img src="../assets/portfolio/langzi.jpg" class="img-fluid" />
                <div class="portfolio-info">
                  <h4>浪滋</h4>
                </div>
              </div>
            </div>
            <!-- partner 7 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img src="../assets/portfolio/mulinsen.jpg" class="img-fluid" />
                <div class="portfolio-info">
                  <h4>木林森</h4>
                </div>
              </div>
            </div>
            <!-- partner 8 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img
                  src="../assets/portfolio/lingyangzaoan.jpg"
                  class="img-fluid"
                />
                <div class="portfolio-info">
                  <h4>羚羊早安</h4>
                </div>
              </div>
            </div>
            <!-- partner 9 -->
            <div class="col-lg-4 col-md-6 portfolio-item">
              <div class="portfolio-item">
                <img src="../assets/portfolio/tuoluzhe.jpg" class="img-fluid" />
                <div class="portfolio-info">
                  <h4>拓路者</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= Our strength ======= -->
      <section id="strength" class="services">
        <div class="container">
          <div class="section-title">
            <h2>我们的优势</h2>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><i class="icofont-computer"></i></div>
              <h4 class="title"><a href="">高品质</a></h4>
              <p class="description">
                我们代销的产品都是来自知名大品牌，口碑好，质量有保证。
              </p>
            </div>

            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><i class="icofont-computer"></i></div>
              <h4 class="title"><a href="">新零售</a></h4>
              <p class="description">
                我们已经整合线上线下零售模式，并在我们的的模式当中融入了直播等各种新型模式，确保不会错过每个流量领域。
              </p>
            </div>

            <div class="col-lg-4 col-md-6 icon-box">
              <div class="icon"><i class="icofont-computer"></i></div>
              <h4 class="title"><a href="">物美价廉</a></h4>
              <p class="description">
                我们致力于让顾客享受到实实在在的物美价廉，以仓储的价格给顾客提供专卖店的品质。
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= Contact Section ======= -->
      <section id="contact" class="contact section-bg">
        <div class="container">
          <div class="section-title">
            <h2>联系我们</h2>
          </div>
          <div class="row info">
            <div class="col-12 col-sm-4">
              <div>
                <!-- <h4>地址：</h4> -->
                <p>
                  <i class="fa fa-map-marker-alt"></i
                  >内蒙古，通辽市，万力皮草成四楼408室
                  <!-- <i class="fa fa-map-marker-alt"></i
                  >内蒙古，通辽市<br />万力皮草成四楼408室 -->
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div>
                <!-- <h4>微信：</h4> -->
                <p><i class="fab fa-weixin"></i>照日格图：13474750666</p>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div>
                <!-- <h4>电话：</h4> -->
                <p><i class="fa fa-phone"></i>0475-8616999</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="container">
        <div class="copyright">
          <span>&#169;</span>{{ new Date().getFullYear() }}
          <strong>依如丽服饰</strong>&nbsp;&nbsp;版权所有
        </div>
        <div class="credits">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >蒙ICP备2020004723号-1</a
          >
        </div>
      </div>
    </footer>
    <!-- Back to top -->
    <a href="#" class="back-to-top"
      ><i class="fas fa-arrow-alt-circle-up"></i
    ></a>
    <!-- <div class="back-to-top">
      <i class="fas fa-arrow-alt-circle-up"></i>
    </div> -->
  </div>
</template>

<script>
// import "../assets/portfolio/vendor/owl.carousel/owl.carousel.min.js";

export default {
  name: "Portfolio",
  setup() {
    // Back to top button
    window.onscroll = function() {
      let backToTop = document.querySelector(".back-to-top");
      if (window.scrollY > 100 && backToTop.style.opacity < 1) {
        backToTop.style.opacity = 1;
      }

      if (window.scrollY < 100 && backToTop.style.opacity > 0) {
        backToTop.style.opacity = 0;
      }
    };

    // document.addEventListener("DOMContentLoaded", function() {
    //   document.querySelector(".back-to-top").click(function() {
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    //   document.animate(
    //     {
    //       scrollTop: 0,
    //     },
    //     1500,
    //     "easeInOutExpo"
    //   );
    //   return false;
    //   });
    // });
  },
};
</script>

<style scoped>
/* @import url("../assets/portfolio/vendor/aos/aos.css"); */
html {
  scroll-behavior: smooth;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f2f9f8;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #555;
}

.section-title p {
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 20px 0;
  background: #2d6760;
}

#header.header-scrolled {
  background: #1d443f;
  height: 60px;
  padding: 10px 0;
}

#header .logo h1 {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

@media (max-width: 768px) {
  #header .logo h1 {
    font-size: 28px;
    padding: 8px 0;
  }
}

#main {
  margin-top: 80px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #d2ece9;
  padding: 10px 15px;
  transition: 0.3s;
  font-size: 14px;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #fff;
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  /* position: absolute; */
  /* left: 0; */
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  /* min-width: 180px; */
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #25564f;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #70b9b0;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -260px;
  width: 260px;
  padding-top: 18px;
  background: #01292a;
  transition: 0.4s;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #d2ece9;
  padding: 10px 20px;
  font-weight: 500;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #9cd5ce;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa0";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(1, 41, 42, 0.9);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 75vh;
  background: url("../assets/portfolio/hero-background.jpg") top center;
  background-size: cover;
  position: relative;
  margin-bottom: -80px;
}

#hero:before {
  content: "";
  background: rgba(112, 185, 176, 0.85);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 300;
}

#hero .btn-get-started {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px 10px 28px;
  border-radius: 50px;
  transition: 0.5s;
  border: 1px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #fff;
  color: #70b9b0;
  border: 1px solid #70b9b0;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about h3 {
  font-weight: 400;
  font-size: 26px;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  padding-bottom: 10px;
}

.about ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #70b9b0;
}

.about p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Our Portfolio
--------------------------------------------------------------*/
.portfolio {
  padding: 60px 0;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 0 15px 15px 0;
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #777;
  border-radius: 4px;
  text-transform: uppercase;
  background: white;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #70b9b0;
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  /* background: #073839; */
  overflow: hidden;
  min-height: 200px;
  position: relative;
  border-radius: 4px;
  margin: 0 0 30px 0;
}

.portfolio .portfolio-item img {
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  transition: all 0.3s linear;
  text-align: center;
  top: 10%;
  left: 0;
  right: 0;
}

.portfolio .portfolio-item .portfolio-info h3 {
  font-size: 22px;
}

.portfolio .portfolio-item .portfolio-info h3 a {
  color: #fff;
  font-weight: bold;
}

.portfolio .portfolio-item .portfolio-info a i {
  color: #fff;
  font-size: 24px;
  transition: 0.3s;
  margin: 4px;
}

.portfolio .portfolio-item .portfolio-info a i:hover {
  color: #70b9b0;
}

.portfolio .portfolio-item:hover img {
  opacity: 0.4;
  transform: scale(1.1);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  top: calc(50% - 30px);
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.services .icon i {
  color: #70b9b0;
  font-size: 42px;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.services .title a {
  color: #343a40;
  transition: 0.3s;
}

.services .icon-box:hover .title a {
  color: #70b9b0;
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  color: #70b9b0;
}

.contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Lato", sans-serif;
  color: #888;
}

.contact .social-links {
  padding-bottom: 20px;
}

.contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #70b9b0;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #70b9b0;
}

.contact .social-links a:hover {
  background: #70b9b0;
  color: #fff;
}

.contact .info {
  color: #777;
}

.contact .info i {
  font-size: 32px;
  color: #70b9b0;
  float: left;
  line-height: 1;
}

.contact .info p {
  padding: 0 0 10px 42px;
  line-height: 28px;
  font-size: 14px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form button[type="submit"] {
  background: #70b9b0;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #92cac3;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #073839;
  padding: 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  /* display: none; */
  opacity: 0;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  background: #70b9b0;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: #8bc6bf;
  transition: background 0.2s ease-in-out;
}
/* Others */

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-lg-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-lg-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-lg-2 {
  -ms-flex-order: 2;
  order: 2;
}
</style>
