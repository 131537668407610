<template>
  <header class="section-header">
    <!-- <nav class="navbar navbar-dark navbar-expand p-0 bg-primary">
      <div class="container">
        <ul class="navbar-nav d-none d-md-flex mr-auto">
          <li class="nav-item"><a class="nav-link" href="#">Yirool</a></li> -->
    <!-- <li class="nav-item"><a class="nav-link" href="#">Delivery</a></li>
          <li class="nav-item"><a class="nav-link" href="#">Payment</a></li> -->
    <!-- </ul> -->
    <!-- <ul class="navbar-nav"> -->
    <!-- <li class="nav-item">
            <a href="#" class="nav-link"> 商户合作: 138-1788-5788 </a>
          </li> -->
    <!-- <li class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
              English
            </a>
            <ul
              class="dropdown-menu dropdown-menu-right"
              style="max-width: 100px;"
            >
              <li><a class="dropdown-item" href="#">Arabic</a></li>
              <li><a class="dropdown-item" href="#">Russian </a></li>
            </ul>
          </li> -->
    <!-- </ul> -->
    <!-- list-inline //  -->
    <!-- </div> -->
    <!-- navbar-collapse .// -->
    <!-- </nav> -->
    <!-- header-top-light.// -->

    <section class="header-main border-bottom">
      <div class="container">
        <!-- <div class="row align-items-center"> -->
        <div class="row align-items-center">
          <div class="col-1 col-md-2 col-lg-2 mr-auto pl-0">
            <a href="http://www.yirool.com" class="brand-wrap">
              <img class="logo" src="../assets/logo.jpg" />
            </a>
            <!-- brand-wrap.// -->
          </div>

          <div class="col-11 col-md-6 col-lg-7 ml-auto">
            <form action="#" class="search">
              <div class="input-group w-100">
                <input
                  type="text"
                  class="form-control"
                  placeholder="查找商品"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="fa fa-search"></i> 查找
                  </button>
                </div>
              </div>
            </form>
            <!-- search-wrap .end// -->
          </div>
          <!-- col.// -->
          <div class="col-md-4 col-lg-3 d-none d-md-block">
            <!-- hide when screen smaller than medium -->
            <div class="widgets-wrap float-lg-right float-right">
              <!-- <div class="widget-header  mr-3">
                <a href="#" class="icon icon-sm rounded-circle border"
                  ><i class="fa fa-shopping-cart"></i
                ></a>
                <span class="badge badge-pill badge-danger notify">0</span>
              </div> -->
              <div class="widget-header icontext">
                <a href="#" class="icon icon-sm rounded-circle border"
                  ><i class="fa fa-phone"></i
                ></a>
                <div class="text">
                  <span class="row ml-1 mr-1">138-1788-5788</span>
                  <span class="row ml-1 mr-1">156-1870-4996</span>
                  <!-- <span class="text-muted">Welcome!</span> -->
                  <!-- <div>
                    <a href="#">Sign in</a> |
                    <a href="#"> Register</a>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- widgets-wrap.// -->
          </div>
          <!-- col.// -->
        </div>
      </div>
    </section>
    <!-- header-main .// -->
  </header>
  <!-- section-header.// -->
</template>

<style scoped></style>
