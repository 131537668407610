<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>图片管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewImage">
            新增图片
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名称</th>
              <th scope="col">备注</th>
              <th scope="col">复制地址</th>
              <th scope="col">删除</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="imgInfo in imageInfoList" :key="imgInfo.id">
              <td scope="row">{{ imgInfo.id }}</td>
              <td>{{ imgInfo.original_image_name }}</td>
              <td>{{ imgInfo.note }}</td>
              <td>
                <a
                  href=""
                  @click.prevent="copyImageUrl(imgInfo.id, imgInfo.image_name)"
                  >复制地址</a
                >
                <input
                  type="hidden"
                  :id="'id' + imgInfo.id"
                  :value="imgInfo.image_name"
                />
              </td>
              <td>
                <a href="" @click.prevent="deleteImage(imgInfo.id)"
                  ><i class="fa fa-times" style="color:red;"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end">
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <!-- <li class="page-item disabled"> -->
              <li :class="[{ disabled: hasPrevPage === false }, 'page-item']">
                <a
                  class="page-link"
                  href=""
                  tabindex="-1"
                  aria-disabled="true"
                  @click.prevent="clickPrevPage"
                  >上一页</a
                >
              </li>

              <div v-for="tempPageNum in totalPageNum" :key="tempPageNum">
                <li :class="[{ active: tempPageNum == pageNum }, 'page-item']">
                  <a
                    class="page-link"
                    href=""
                    @click.prevent="clickPageNum(tempPageNum)"
                    >{{ tempPageNum }}</a
                  >
                </li>
              </div>

              <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item active" aria-current="page">
                <a class="page-link" href="#">2</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">3</a></li> -->
              <li :class="[{ disabled: hasNextPage === false }, 'page-item']">
                <a class="page-link" href="" @click.prevent="clickNextPage"
                  >下一页</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- modal -->

    <!-- for upload image -->
    <Modal>
      <template v-slot:modalBody>
        <div class="fileUpload btn btn-primary">
          <span>选择图片</span>
          <input
            id="chooseFile"
            type="file"
            class="upload"
            @change="onFileChange"
          />
        </div>
        <input
          id="uploadFile"
          :placeholder="
            selectedFileForUpload.length == 0
              ? '图片名称'
              : selectedFileForUpload.name
          "
          disabled="disabled"
        />

        <!-- <div class=" file-upload">
          <input type="file" @change="onFileChange" id="chooseFile" />
          <button
            @click="onUploadFile"
            class="upload-button"
            :disabled="!selectedFileForUpload"
          >
            上传图片
          </button>
        </div> -->
      </template>
      <template v-slot:footerButton>
        图片备注：
        <input type="text" v-model="imageNoteForNewImage" />
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!selectedFileForUpload"
          @click="onUploadFile"
        >
          上传图片
        </button>
      </template>
    </Modal>

    <!-- live toast -->
    <div
      class="position-fixed bottom-0 right-0 p-3"
      style="z-index: 5; right: 0; top: 0;"
    >
      <div
        id="liveToast"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
      >
        <div class="toast-header" style="background:#8a2be2">
          <!-- <img src="..." class="rounded mr-2" alt="..." /> -->
          <i class="fa fa-bell" style="color:white"></i>
          <strong class="mr-auto" style="color:white">Yirool</strong>
          <small style="color:white">刚刚</small>
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body" style="background:#f0f8ff">
          图片已经被复制。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "ImageList",
  components: { Modal },
  setup() {
    // use store
    const store = useStore();
    // load image info
    async function loadImageInfo() {
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadImageList", {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      });
      totalItemNum.value = response.data.rows.length;
      imageInfoList.value = response.data.rows;
      isNeedToShowSpinner.value = false;
    }
    // for add new Image
    function showModalForAddNewImage() {
      console.log("displayModal:");
      store.commit("displayModal", {
        modalTitle: "添加新图片",
      });
    }
    // for delete image
    async function deleteImage(id) {
      let askConfirm = confirm(`确定要删除 ${id} 号图片吗？`);
      if (askConfirm == true) {
        let response = await store.dispatch("deleteImageById", id);
        if (response.data == true) {
          imageInfoList.value = imageInfoList.value.filter((x) => x.id != id);
        }
      } else {
        // txt = "You pressed Cancel!";
      }
    }

    const pageNum = ref(1);
    const pageSize = ref(20);
    const totalItemNum = ref(0);
    const imageInfoList = ref([]);

    // for paganation:
    const hasPrevPage = computed(() => {
      if (pageNum.value > 1) {
        return true;
      } else {
        return false;
      }
    });
    const totalPageNum = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      return howManyPage;
    });
    const hasNextPage = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      if (pageNum.value >= howManyPage) {
        return false;
      } else {
        return true;
      }
    });

    function clickPageNum(clickedPageNum) {
      if (clickedPageNum != pageNum.value) {
        pageNum.value = clickedPageNum;
        loadImageInfo();
      }
    }

    function clickPrevPage() {
      pageNum.value--;
      loadImageInfo();
    }
    function clickNextPage() {
      pageNum.value++;
      loadImageInfo();
    }

    // life cycle hook
    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadImageInfo();
      isNeedToShowSpinner.value = false;
    });

    // for upload file:
    const selectedFileForUpload = ref("");
    const imageNoteForNewImage = ref("");

    function onFileChange(e) {
      console.log("onFileChange", e.target.files[0].type);

      if (e.target.files[0].type.includes("image")) {
        selectedFileForUpload.value = e.target.files[0]; // accessing file
        // default note for new image
        imageNoteForNewImage.value = selectedFileForUpload.value.name;
      } else {
        selectedFileForUpload.value = "";
        imageNoteForNewImage.value = "";
      }
    }

    async function onUploadFile() {
      const formData = new FormData();
      formData.append("file", selectedFileForUpload.value);
      if (imageNoteForNewImage.value) {
        console.log("image note:", imageNoteForNewImage.value);
        formData.append("imageNote", imageNoteForNewImage.value);
      } else {
        console.log("no image note");
      }

      await store.dispatch("uploadImageToServer", formData);
      selectedFileForUpload.value = "";
      imageNoteForNewImage.value = "";
      store.commit("hideModal");
      loadImageInfo();
    }

    function copyImageUrl(imageId, imageName) {
      try {
        console.log("imageId", imageId);
        let linkItemToCopy = document.querySelector("#id" + imageId); // element id cannot start with number.
        // console.log("linkItemToCopy", linkItemToCopy);
        let url = store.state.imageServerAddress + imageName;
        linkItemToCopy.value = url;

        linkItemToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
        linkItemToCopy.select();
        let successful = document.execCommand("copy");
        linkItemToCopy.setAttribute("type", "hidden");
        // let message = successful ? "复制成功！" : "复制失败！";
        // alert(message);
        if (successful) {
          let toast = document.querySelector("#liveToast");
          toast.classList.add("show");
          setTimeout(function() {
            toast.classList.remove("show");
          }, 2000);
          // https://dev.to/tsanak/create-a-simple-0-dependency-toast-1bg5
        }

        // container.getElementsByClassName("toast").toast("show");
        // document.getElementById("liveToast").toast("show");
        // 、、 https://getbootstrap.com/docs/4.6/components/toasts/
        // $("#liveToast").toast('show');
        // console.log(document.querySelector("#liveToast"));
      } catch (err) {}
    }

    return {
      imageInfoList,
      showModalForAddNewImage,
      deleteImage,
      selectedFileForUpload,
      onFileChange,
      imageNoteForNewImage,
      onUploadFile,
      copyImageUrl,
      pageNum,
      hasPrevPage,
      totalPageNum,
      hasNextPage,
      clickPageNum,
      clickPrevPage,
      clickNextPage,
      isNeedToShowSpinner,
    };
  },
};
</script>

<style scoped>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
