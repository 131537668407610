<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>子产品管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewPV">
            新增子产品
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名称</th>
              <th scope="col">SKU-Code</th>
              <th scope="col">条形码</th>
              <th scope="col">发布</th>
              <th scope="col">编辑</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pvInfo in pvInfoList" :key="pvInfo.id">
              <td scope="row">{{ pvInfo.id }}</td>
              <td>{{ pvInfo.name }}</td>
              <td>{{ pvInfo.sku_code }}</td>
              <td>{{ pvInfo.upc }}</td>
              <td>
                <span v-if="pvInfo.published">
                  <i class="fa fa-check" style="color:green;"></i>
                </span>
                <span v-else>
                  <i class="fa fa-times" style="color:red;"></i>
                </span>
              </td>
              <td>
                <a href="" @click.prevent="showModalForEditPV(pvInfo.id)"
                  >编辑</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end">
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <!-- <li class="page-item disabled"> -->
              <li :class="[{ disabled: hasPrevPage === false }, 'page-item']">
                <a
                  class="page-link"
                  href=""
                  tabindex="-1"
                  aria-disabled="true"
                  @click.prevent="clickPrevPage"
                  >上一页</a
                >
              </li>

              <div v-for="tempPageNum in totalPageNum" :key="tempPageNum">
                <li :class="[{ active: tempPageNum == pageNum }, 'page-item']">
                  <a
                    class="page-link"
                    href=""
                    @click.prevent="clickPageNum(tempPageNum)"
                    >{{ tempPageNum }}</a
                  >
                </li>
              </div>

              <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item active" aria-current="page">
                <a class="page-link" href="#">2</a>
              </li>
              <li class="page-item"><a class="page-link" href="#">3</a></li> -->
              <li :class="[{ disabled: hasNextPage === false }, 'page-item']">
                <a class="page-link" href="" @click.prevent="clickNextPage"
                  >下一页</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- modal -->
    <Modal>
      <template v-slot:modalBody>
        <form>
          <div class="form-row">
            <!-- <div class="form-group col-md-6"> -->
            <div class="form-group col-12">
              <label for="pvName" class="float-left"
                >子产品名称
                <span style="color:red"> * 必填</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="pvName"
                v-model="pvForNewOrEdit.name"
              />
            </div>
            <!-- pv product_id -->
            <div class="form-group col-12">
              <label for="pvProductId" class="float-left"
                >选择父产品<span style="color:red"> * 必填</span></label
              >
              <div class="dropdown">
                <input
                  :disabled="pvForNewOrEdit.id"
                  id="pvProductId"
                  type="text"
                  class="form-control dropdown-input"
                  placeholder="输入父产品SKU编码"
                  v-model="searchProductSKUCodePrefix"
                />
                <div v-show="searchResultProductInfoList.length > 0">
                  <div class="dropdown-list">
                    <div
                      v-for="item in searchResultProductInfoList"
                      :key="item.id"
                      class="dropdown-item dropdown-selected"
                      @click="selectItemFromSearchResult(item)"
                    >
                      {{ item.name }} - {{ item.sku_code_prefix }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group" style="width:100%">
              <label for="pvProductId" class="float-left">父产品</label>
              <select
                :disabled="pvForNewOrEdit.product_id"
                class="form-control"
                id="pvProductId"
                v-model="pvForNewOrEdit.product_id"
              >
              </select>
            </div> -->
            <!-- pv country -->
            <div class="form-group col-12">
              <label for="pvCountry" class="float-left">原产国</label>
              <input
                type="text"
                class="form-control"
                id="pvCountry"
                v-model="pvForNewOrEdit.country"
              />
            </div>
            <!-- pv upc -->
            <div class="form-group col-12">
              <label for="pvUPC" class="float-left">条形码</label>
              <input
                type="text"
                class="form-control"
                id="pvUPC"
                v-model="pvForNewOrEdit.upc"
              />
            </div>
            <!-- pv batch_number -->
            <div class="form-group col-12">
              <label for="pvBatchNumber" class="float-left">批次号</label>
              <input
                type="text"
                class="form-control"
                id="pvBatchNumber"
                v-model="pvForNewOrEdit.batch_number"
              />
            </div>
            <!-- pv color -->
            <div class="form-group col-12">
              <label for="pvColor" class="float-left">颜色（可多填）</label>
              <input
                type="text"
                class="form-control"
                id="pvColor"
                v-model="pvForNewOrEdit.color"
              />
            </div>
            <!-- pv item_size  -->
            <div class="form-group col-12">
              <label for="pvItemSize" class="float-left">尺寸</label>
              <input
                type="text"
                class="form-control"
                id="pvItemSize"
                v-model="pvForNewOrEdit.item_size"
              />
            </div>
            <!-- pv weight_kg   -->
            <div class="form-group col-12">
              <label for="pvWeightKG" class="float-left">重量（公斤）</label>
              <input
                type="number"
                class="form-control"
                id="pvWeightKG"
                v-model="pvForNewOrEdit.weight_kg"
              />
            </div>
            <!-- pv short_description    -->
            <div class="form-group col-12">
              <label for="pvShortDescription" class="float-left">简介</label>
              <input
                type="text"
                class="form-control"
                id="pvShortDescription"
                v-model="pvForNewOrEdit.short_description"
              />
            </div>
            <!-- pv full_description    -->
            <div class="form-group col-12">
              <label for="pvFullDescription" class="float-left">详细介绍</label>
              <textarea
                type="text"
                rows="2"
                class="form-control"
                id="pvFullDescription"
                v-model="pvForNewOrEdit.full_description"
              />
            </div>
            <!-- pv product_cost_cny    -->
            <div class="form-group col-12">
              <label for="pvCostCNY" class="float-left"
                >成本价（人民币，小数点后两位）</label
              >
              <input
                type="number"
                class="form-control"
                id="pvCostCNY"
                v-model="pvForNewOrEdit.cost_cny"
              />
            </div>
            <!-- pv price_cny    -->
            <div class="form-group col-12">
              <label for="pvPriceCNY" class="float-left"
                >价格（人民币，小数点后两位）</label
              >
              <input
                type="number"
                class="form-control"
                id="pvPriceCNY"
                v-model="pvForNewOrEdit.price_cny"
              />
            </div>
            <!-- pv promotion price_cny    -->
            <div class="form-group col-12">
              <label for="pvPromotionPriceCNY" class="float-left"
                >促销价格（人民币，小数点后两位）</label
              >
              <input
                type="number"
                class="form-control"
                id="pvPromotionPriceCNY"
                v-model="pvForNewOrEdit.promotion_price_cny"
              />
            </div>
            <!-- pv stock_num    -->
            <div class="form-group col-12">
              <label for="pvStockNum" class="float-left">库存（件）</label>
              <input
                type="number"
                class="form-control"
                id="pvStockNum"
                v-model="pvForNewOrEdit.stock_num"
              />
            </div>
            <!-- pv recommandation_rate    -->
            <div class="form-group col-12">
              <label for="pvRecommandationRate" class="float-left"
                >推荐指数 1--5（5分满分）</label
              >
              <select
                class="form-control"
                id="pvRecommandationRate"
                v-model="pvForNewOrEdit.recommendation_rate"
              >
                <option :value="1">1</option>
                <option :value="2">2</option>
                <option :value="3">3</option>
                <option :value="4">4</option>
                <option :value="5">5</option>
              </select>
            </div>
            <!-- pv display_order     -->
            <div class="form-group col-12">
              <label for="pvDisplayOrder" class="float-left"
                >显示优先级（越小越高）</label
              >
              <select
                class="form-control"
                id="pvRecommandationRate"
                v-model="pvForNewOrEdit.display_order"
              >
                <option :value="1">1</option>
                <option :value="2">2</option>
                <option :value="3">3</option>
                <option :value="4">4</option>
                <option :value="5">5</option>
                <option :value="6">6</option>
                <option :value="7">7</option>
                <option :value="8">8</option>
                <option :value="9">9</option>
                <option :value="10">10</option>
              </select>
            </div>
            <!-- pv tags      -->
            <div class="form-group col-12">
              <label for="pvTags" class="float-left">关键字（可多填）</label>
              <input
                type="text"
                class="form-control"
                id="pvTags"
                v-model="pvForNewOrEdit.key_words"
              />
            </div>
            <!-- pv category id      -->
            <div class="form-group col-12">
              <label for="pvCategoryId" class="float-left">三级分类</label>
              <select
                class="form-control"
                id="pvCategoryId"
                v-model="pvForNewOrEdit.category_id"
              >
                <option
                  v-for="category in levelThreeCategoryList"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <!-- pv published    -->
            <div class="form-group col-12">
              <div class="form-check float-left">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="checkPublish"
                  v-model="pvForNewOrEdit.published"
                />
                <label class="form-check-label" for="checkPublish">
                  发布 ( 请打勾<span
                    ><i class="fa fa-check" style="color:green;"></i
                  ></span>
                  )
                </label>
              </div>
            </div>
            <!-- pv image_urls     -->
            <div class="form-group col-12">
              <label for="pvImageUrls" class="float-left"
                >图片链接（多个链接使用分号分开）</label
              >
              <textarea
                type="text"
                rows="2"
                class="form-control"
                id="pvImageUrls"
                v-model="pvForNewOrEdit.image_urls"
              />
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footerButton>
        <button type="button" class="btn btn-primary" @click="savePVInfo">
          保存
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "ProductVariantList",
  components: { Modal },
  setup() {
    // for search product and select
    const searchProductSKUCodePrefix = ref("");
    const searchResultProductInfoList = ref([]);
    const selectedProduct = ref({});

    watch(searchProductSKUCodePrefix, async (newVal, oldVal) => {
      console.log("newVal", newVal);
      console.log("oldVal", oldVal);
      if (newVal && newVal != oldVal) {
        console.log("searchProductBySKUPrefix", newVal);
        let result = await store.dispatch("searchProductBySKUPrefix", {
          sku_code_prefix: newVal,
        });
        console.log("result:", result.data);
        searchResultProductInfoList.value = result.data;
      }
    });
    function selectItemFromSearchResult(item) {
      console.log("item", item);
      selectedProduct.value = item;
      console.log(selectedProduct.value);
      searchProductSKUCodePrefix.value =
        selectedProduct.value.id +
        " - " +
        selectedProduct.value.name +
        " - " +
        selectedProduct.value.sku_code_prefix;
      searchResultProductInfoList.value = [];
    }
    // life cyicle
    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadPVInfoList();
      await loadLevelThreeCategoryList();
      isNeedToShowSpinner.value = false;
    });
    const store = useStore();
    // for load product list
    const pvInfoList = ref([]);
    const levelThreeCategoryList = ref([]);

    async function loadPVInfoList() {
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadPVInfoList", {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      });
      console.log(response);
      totalItemNum.value = response.data.rows.length;
      pvInfoList.value = response.data.rows;
      isNeedToShowSpinner.value = false;
    }
    async function loadLevelThreeCategoryList() {
      let response = await store.dispatch("loadLevelThreeCategoryList");
      levelThreeCategoryList.value = response.data;
      console.log("------------", levelThreeCategoryList.value);
    }
    // for add or edit product
    const pvForNewOrEdit = ref({});

    function showModalForAddNewPV() {
      pvForNewOrEdit.value = {};
      searchProductSKUCodePrefix.value = "";
      searchResultProductInfoList.value = [];
      store.commit("displayModal", {
        modalTitle: "添加子产品",
      });
    }
    function showModalForEditPV(id) {
      let title = "编辑-" + pvInfoList.value.filter((x) => x.id == id)[0].name;
      pvForNewOrEdit.value = pvInfoList.value.filter((x) => x.id == id)[0];

      // for: check box not checked when mysql returning 1 for true
      if (
        pvForNewOrEdit.value &&
        pvForNewOrEdit.value.published &&
        pvForNewOrEdit.value.published == 1
      ) {
        pvForNewOrEdit.value.published = true;
      }
      store.commit("displayModal", {
        modalTitle: title,
      });
    }

    const formErrorMessages = ref([]);

    async function savePVInfo() {
      console.log(pvForNewOrEdit.value);
      formErrorMessages.value = [];
      if (
        !pvForNewOrEdit.value ||
        !pvForNewOrEdit.value.name ||
        pvForNewOrEdit.value.name.trim() == ""
      ) {
        formErrorMessages.value.push("需要输入子产品名称");
      }

      if (!pvForNewOrEdit.value.id) {
        if (!selectedProduct.value || !selectedProduct.value.name) {
          formErrorMessages.value.push("请选择父产品"); // this is only for new pv.
        }
      }

      if (formErrorMessages.value.length > 0) {
        alert(formErrorMessages.value[0]);
        return;
      }

      if (pvForNewOrEdit.value.id && pvForNewOrEdit.value.id > 0) {
        //  edit
        console.log("Edit");
        //   console.log(productForNewOrEdit.value);
        store.commit("hideModal");
        await store.dispatch("updatePVInfo", pvForNewOrEdit.value);
        loadPVInfoList();
      } else {
        // add new

        console.log("Add new one");
        pvForNewOrEdit.value.product_id = selectedProduct.value.id;
        console.log(pvForNewOrEdit.value);
        await store.dispatch("addNewPVInfo", pvForNewOrEdit.value);
        store.commit("hideModal");
        loadPVInfoList();
      }
    }

    // for paganation:
    const pageNum = ref(1);
    const pageSize = ref(20);
    const totalItemNum = ref(0);
    // const imageInfoList = ref([]);
    const hasPrevPage = computed(() => {
      if (pageNum.value > 1) {
        return true;
      } else {
        return false;
      }
    });
    const totalPageNum = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      return howManyPage;
    });
    const hasNextPage = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      if (pageNum.value >= howManyPage) {
        return false;
      } else {
        return true;
      }
    });

    function clickPageNum(clickedPageNum) {
      if (clickedPageNum != pageNum.value) {
        pageNum.value = clickedPageNum;
        loadPVInfoList();
      }
    }

    function clickPrevPage() {
      pageNum.value--;
      loadPVInfoList();
    }
    function clickNextPage() {
      pageNum.value++;
      loadPVInfoList();
    }

    return {
      searchProductSKUCodePrefix,
      searchResultProductInfoList,
      selectItemFromSearchResult,
      pvInfoList,
      levelThreeCategoryList,
      showModalForAddNewPV,
      showModalForEditPV,
      pvForNewOrEdit,
      savePVInfo,
      pageNum,
      hasPrevPage,
      totalPageNum,
      hasNextPage,
      clickPageNum,
      clickPrevPage,
      clickNextPage,
      isNeedToShowSpinner,
    };
  },
};
</script>

<style scoped>
.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}
</style>
