import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import AdminLogin from "../views/AdminLogin";
import Admin from "../views/Admin";
import ProductList from "../components/Admin/ProductList";
import ProductVariantList from "../components/Admin/ProductVariantList";
import SupplierList from "../components/Admin/SupplierList";
import BrandList from "../components/Admin/BrandList";
import ImageList from "../components/Admin/ImageList";
import CategoryList from "../components/Admin/CategoryList";
import PageNotFound from "../views/PageNotFound";
import Portfolio from "../views/Portfolio";

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    component: Portfolio,
  },
  {
    path: "/AdminLogin",
    name: "AdminLogin",
    component: AdminLogin,
    meta: {
      guest: true, // which means only users not authenticated will see it
    },
  },
  {
    path: "/yirool-admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
    component: Admin,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
    children: [
      {
        path: "/yirool-admin/ProductVariantList",
        name: "ProductVariantList",
        component: ProductVariantList,
      },
      {
        path: "/yirool-admin/SupplierList",
        name: "SupplierList",
        component: SupplierList,
      },
      {
        path: "/yirool-admin/ProductList",
        name: "ProductList",
        component: ProductList,
      },
      {
        path: "/yirool-admin/BrandList",
        name: "BrandList",
        component: BrandList,
      },
      {
        path: "/yirool-admin/ImageList",
        name: "ImageList",
        component: ImageList,
      },
      {
        path: "/yirool-admin/CategoryList",
        name: "CategoryList",
        component: CategoryList,
      },
    ],
  },

  {
    path: "/:catchAll(.*)", // catch all routes that not catched our router.
    name: "PageNotFound",
    component: PageNotFound,
  },
];

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'login' && !isAuthenticated) {
//     next({ name: 'login' });
//   } else {
//     next();
//   }
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("yirool-admin") == null) {
      next({
        // path: "/AdminLogin",
        name: "AdminLogin",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let yiroolAdmin = JSON.parse(localStorage.getItem("yirool-admin"));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (yiroolAdmin.user_roles.includes("admin")) {
          next();
        } else {
          next({ name: "Home" });
        }
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("yirool-admin") == null) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
