<template>
  <div class="home">
    <Header />
    <Navbar />
    <Banner />
    <MainProductList />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MainProductList from "../components/MainProductList";
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    Banner,
    MainProductList,
    Footer,
  },
};
</script>
