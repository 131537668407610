import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "jquery";
import "bootstrap";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/ui.css";
import "./assets/css/responsive.css";

import axios from "axios";
import VueAxios from "vue-axios";

createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapIconsPlugin)
  .use(VueAxios, axios)
  .mount("#app");
