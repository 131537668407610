<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>产品管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewProduct">
            新增产品
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">品牌</th>
              <th scope="col">产品名称</th>
              <th scope="col">SKU编码</th>
              <th scope="col">子产品数量</th>
              <th scope="col">编辑</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pInfo in productInfoList" :key="pInfo.id">
              <td scope="row">{{ pInfo.id }}</td>
              <td>{{ pInfo.brand_name }}</td>
              <td>{{ pInfo.name }}</td>
              <td>{{ pInfo.sku_code_prefix }}</td>
              <td>{{ pInfo.product_variant_count }}</td>
              <td>
                <a href="" @click.prevent="showModalForEditProduct(pInfo.id)"
                  >编辑</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end">
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <li :class="[{ disabled: hasPrevPage === false }, 'page-item']">
                <a
                  class="page-link"
                  href=""
                  tabindex="-1"
                  aria-disabled="true"
                  @click.prevent="clickPrevPage"
                  >上一页</a
                >
              </li>

              <div v-for="tempPageNum in totalPageNum" :key="tempPageNum">
                <li :class="[{ active: tempPageNum == pageNum }, 'page-item']">
                  <a
                    class="page-link"
                    href=""
                    @click.prevent="clickPageNum(tempPageNum)"
                    >{{ tempPageNum }}</a
                  >
                </li>
              </div>
              <li :class="[{ disabled: hasNextPage === false }, 'page-item']">
                <a class="page-link" href="" @click.prevent="clickNextPage"
                  >下一页</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- modal -->
    <Modal>
      <template v-slot:modalBody>
        <form>
          <div class="form-row">
            <!-- <div class="form-group col-md-6"> -->
            <div class="form-group col-12">
              <label for="productName" class="float-left">名称</label>
              <input
                type="text"
                class="form-control"
                id="productName"
                v-model="productForNewOrEdit.name"
              />
            </div>

            <div class="form-group" style="width:100%">
              <label for="productBrand" class="float-left">品牌</label>
              <select
                :disabled="productForNewOrEdit.id"
                class="form-control"
                id="productBrand"
                v-model="productForNewOrEdit.brand_id"
              >
                <option
                  v-for="brand in allBrandList"
                  :key="brand.id"
                  :value="brand.id"
                  >{{ brand.name }}</option
                >
              </select>
            </div>
            <div class="form-group col-12">
              <label for="productCountry" class="float-left">所属国家</label>
              <input
                type="text"
                class="form-control"
                id="productCountry"
                v-model="productForNewOrEdit.country"
              />
            </div>
            <div class="form-group col-12">
              <label for="productShortDescription" class="float-left"
                >产品简介</label
              >
              <input
                type="text"
                class="form-control"
                id="productShortDescription"
                v-model="productForNewOrEdit.short_description"
              />
            </div>
            <div class="form-group col-12">
              <label for="productFullDescription" class="float-left"
                >产品详细介绍</label
              >
              <textarea
                type="text"
                rows="2"
                class="form-control"
                id="productFullDescription"
                v-model="productForNewOrEdit.full_description"
              />
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footerButton>
        <button type="button" class="btn btn-primary" @click="saveProductInfo">
          保存
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "ProductList",
  components: { Modal },
  setup() {
    const store = useStore();
    // for add new product
    function showModalForAddNewProduct() {
      productForNewOrEdit.value = {};
      store.commit("displayModal", {
        modalTitle: "添加新产品",
      });
    }
    // for edit product info
    function showModalForEditProduct(id) {
      let title =
        "编辑-" + productInfoList.value.filter((x) => x.id == id)[0].name;
      productForNewOrEdit.value = productInfoList.value.filter(
        (x) => x.id == id
      )[0];
      store.commit("displayModal", {
        modalTitle: title,
      });
    }

    // for load product list
    const productInfoList = ref([]);

    async function loadProductInfoList() {
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadProductInfoList", {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      });
      totalItemNum.value = response.data.rows.length;
      productInfoList.value = response.data.rows;
      productInfoList.value.forEach(async (e) => {
        let response = await store.dispatch("getProductVariantNumForBrand", {
          product_id: e.id,
        });
        e.product_variant_count = response.data[0].count;
        console.log(response.data[0].count);
      });
      isNeedToShowSpinner.value = false;
      console.log(productInfoList.value);
    }
    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadProductInfoList();
      await loadAllBrandList();
      isNeedToShowSpinner.value = false;
    });

    // add or edit brand
    const productForNewOrEdit = ref({});
    const allBrandList = ref([]);

    async function loadAllBrandList() {
      let response = await store.dispatch("getAllBrandList");
      response.data.forEach((element) => {
        allBrandList.value.push(element);
      });
      console.log(allBrandList.value);
    }

    async function saveProductInfo() {
      console.log(productForNewOrEdit.value);
      if (
        !productForNewOrEdit.value.brand_id ||
        productForNewOrEdit.value.brand_id < 1
      ) {
        alert("请选择供品牌。");
        return;
      }

      if (productForNewOrEdit.value.id && productForNewOrEdit.value.id > 0) {
        // edit
        console.log("Edit");
        console.log(productForNewOrEdit.value);
        store.commit("hideModal");
        await store.dispatch("updateProductInfo", productForNewOrEdit.value);
        loadProductInfoList();
      } else {
        // add new
        if (
          productForNewOrEdit.value &&
          productForNewOrEdit.value.name &&
          productForNewOrEdit.value.name.trim() != ""
        ) {
          console.log("Add new one");
          // console.log(supplierForNewOrEdit.value);
          await store.dispatch("addNewProductInfo", productForNewOrEdit.value);
          store.commit("hideModal");
          loadProductInfoList();
        } else {
          alert("名称不能为空。");
          return;
        }
      }
    }

    // for paganation:
    const pageNum = ref(1);
    const pageSize = ref(20);
    const totalItemNum = ref(0);
    const hasPrevPage = computed(() => {
      if (pageNum.value > 1) {
        return true;
      } else {
        return false;
      }
    });
    const totalPageNum = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      return howManyPage;
    });
    const hasNextPage = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      if (pageNum.value >= howManyPage) {
        return false;
      } else {
        return true;
      }
    });

    function clickPageNum(clickedPageNum) {
      if (clickedPageNum != pageNum.value) {
        pageNum.value = clickedPageNum;
        loadProductInfoList();
      }
    }

    function clickPrevPage() {
      pageNum.value--;
      loadProductInfoList();
    }
    function clickNextPage() {
      pageNum.value++;
      loadProductInfoList();
    }

    return {
      productInfoList,
      showModalForAddNewProduct,
      showModalForEditProduct,
      productForNewOrEdit,
      allBrandList,
      saveProductInfo,
      pageNum,
      hasPrevPage,
      totalPageNum,
      hasNextPage,
      clickPageNum,
      clickPrevPage,
      clickNextPage,
      isNeedToShowSpinner,
    };
  },
};
</script>
