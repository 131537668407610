<template>
  <div>
    <!-- header -->
    <div class="container-fluid">
      <h2 class="text-center py-4">
        管理员后台
      </h2>
      <nav class="nav justify-content-center nav-pills">
        <a
          href=""
          :class="[
            { active: selectedArea == 'ProductVariantList' },
            'nav-link mx-2',
          ]"
          @click.prevent="ManageProductVariants"
          >子产品管理
        </a>

        <a
          href=""
          :class="[{ active: selectedArea == 'ProductList' }, 'nav-link mx-2']"
          @click.prevent="ManageProducts"
          >产品管理</a
        >
        <a
          href=""
          :class="[{ active: selectedArea == 'BrandList' }, 'nav-link mx-2']"
          @click.prevent="ManageBrands"
          >品牌管理</a
        >
        <a
          href=""
          :class="[{ active: selectedArea == 'SupplierList' }, 'nav-link mx-2']"
          @click.prevent="ManageSupplier"
          >供应商管理</a
        >
        <a
          href=""
          :class="[{ active: selectedArea == 'CategoryList' }, 'nav-link mx-2']"
          @click.prevent="ManageCategory"
          >类目管理</a
        >
        <a
          href=""
          :class="[{ active: selectedArea == 'ImageList' }, 'nav-link mx-2']"
          @click.prevent="ManageImages"
          >图片管理</a
        >
      </nav>
    </div>
    <!-- content -->
    <hr />
    <router-view />
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Admin",
  setup() {
    const router = useRouter();
    const selectedArea = ref("");

    // onMounted(() => {
    //   ManageProductVariants();
    // });

    function ManageProductVariants() {
      console.log("ProductVariantList");
      selectedArea.value = "ProductVariantList";
      router.push({ name: "ProductVariantList" });
    }
    function ManageImages() {
      selectedArea.value = "ImageList";
      router.push({ name: "ImageList" });
    }
    function ManageProducts() {
      selectedArea.value = "ProductList";
      router.push({ name: "ProductList" });
    }
    function ManageBrands() {
      selectedArea.value = "BrandList";
      router.push({ name: "BrandList" });
    }
    function ManageSupplier() {
      selectedArea.value = "SupplierList";
      router.push({ name: "SupplierList" });
    }
    function ManageCategory() {
      selectedArea.value = "CategoryList";
      router.push({ name: "CategoryList" });
    }

    const userName = ref("");
    const password = ref("");

    return {
      selectedArea,
      ManageProductVariants,
      ManageImages,
      ManageProducts,
      ManageBrands,
      ManageSupplier,
      ManageCategory,
      userName,
      password,
    };
  },
};
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: blue;
}
</style>
