<template>
  <body class="text-center">
    <form class="form-signin">
      <img
        class="mb-4"
        src="../assets/logo.jpg"
        alt=""
        width="72"
        height="72"
      />
      <h1 class="h3 mb-3 font-weight-normal">管理员登陆</h1>
      <label for="inputEmail" class="sr-only">Account</label>
      <input
        type="text"
        id="inputAccount"
        class="form-control"
        placeholder="账号"
        required
        autofocus
        v-model="userName"
      />
      <label for="inputPassword" class="sr-only">Password</label>
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="密码"
        required
        v-model="userPassword"
      />
      <div class="checkbox mb-3">
        <!-- <label>
          <input type="checkbox" value="remember-me" /> Remember me
        </label> -->
      </div>
      <button class="btn btn-lg btn-primary btn-block" @click.prevent="login">
        登录
      </button>
      <!-- <p class="mt-5 mb-3 text-muted">&copy; 2017-2021</p> -->
    </form>
  </body>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "AdminLogin",
  setup() {
    const store = useStore();
    const router = useRouter();

    const userName = ref("");
    const userPassword = ref("");

    async function login() {
      if (!userName.value || !userPassword.value) {
        alert("输入信息有误。");
        return;
      } else {
        let adminLoginResult = await store.dispatch("adminLogin", {
          user_name: userName.value,
          password: userPassword.value,
        });
        console.log("adminLoginResult", adminLoginResult);
        if (
          adminLoginResult &&
          adminLoginResult.status == 200 &&
          adminLoginResult.data.is_auth
        ) {
          localStorage.setItem(
            "yirool-admin",
            JSON.stringify(adminLoginResult.data.user)
          );
          if (localStorage.getItem("yirool-admin") != null) {
            // redirect user to admin page:
            router.push({ name: "Admin" });
          }
        } else {
          alert("无法验证。");
          return;
        }
      }
    }

    return {
      userName,
      userPassword,
      login,
    };
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
