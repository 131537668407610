<template>
  <!-- modal -->
  <div id="modal">
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ modalTitle }}</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="closeModal">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <slot name="modalBody"></slot>
                </div>
                <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-primary">保存</button> -->
                  <slot name="footerButton"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "Modal",
  setup() {
    const store = useStore();
    const showModal = computed(() => {
      return store.state.showModal;
    });
    const modalTitle = computed(() => {
      return store.state.modalTitle;
    });

    function closeModal() {
      store.commit("hideModal");
    }

    return { showModal, modalTitle, closeModal };
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
