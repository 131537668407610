<template>
  <div>
    <div class="container">
      <div class="row pb-0">
        <div class="col text-left">
          <h4>品牌管理页</h4>
        </div>
        <div class="col" v-show="isNeedToShowSpinner">
          <div class="row">
            <div class="spinner-border text-info" role="status"></div>
            <div>&nbsp;数据加载中...</div>
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="showModalForAddNewBrand">
            新增品牌
          </button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">名称</th>
              <th scope="col">SKU编码</th>
              <th scope="col">产品数量</th>
              <th scope="col">编辑</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bInfo in brandInfoList" :key="bInfo.id">
              <td scope="row">{{ bInfo.id }}</td>
              <td>{{ bInfo.name }}</td>
              <td>{{ bInfo.sku_code_prefix }}</td>
              <td>{{ bInfo.product_count }}</td>
              <td>
                <a href="" @click.prevent="showModalForEditBrand(bInfo.id)"
                  >编辑</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-end">
        <div>
          <nav aria-label="">
            <ul class="pagination">
              <li :class="[{ disabled: hasPrevPage === false }, 'page-item']">
                <a
                  class="page-link"
                  href=""
                  tabindex="-1"
                  aria-disabled="true"
                  @click.prevent="clickPrevPage"
                  >上一页</a
                >
              </li>

              <div v-for="tempPageNum in totalPageNum" :key="tempPageNum">
                <li :class="[{ active: tempPageNum == pageNum }, 'page-item']">
                  <a
                    class="page-link"
                    href=""
                    @click.prevent="clickPageNum(tempPageNum)"
                    >{{ tempPageNum }}</a
                  >
                </li>
              </div>
              <li :class="[{ disabled: hasNextPage === false }, 'page-item']">
                <a class="page-link" href="" @click.prevent="clickNextPage"
                  >下一页</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- modal -->
    <Modal>
      <template v-slot:modalBody>
        <form>
          <div class="form-row">
            <!-- <div class="form-group col-md-6"> -->
            <div class="form-group col-12">
              <label for="brandName" class="float-left">名称</label>
              <input
                type="text"
                class="form-control"
                id="brandName"
                v-model="brandForNewOrEdit.name"
              />
            </div>

            <div class="form-group" style="width:100%">
              <label for="brandSupplier" class="float-left">品牌供应商</label>
              <select
                class="form-control"
                id="brandSupplier"
                v-model="brandForNewOrEdit.supplier_id"
              >
                <option
                  v-for="supplier in allSupplierList"
                  :key="supplier.id"
                  :value="supplier.id"
                  >{{ supplier.name }}</option
                >
              </select>
            </div>
            <div class="form-group col-12">
              <label for="brandCountry" class="float-left">所属国家</label>
              <input
                type="text"
                class="form-control"
                id="brandCountry"
                v-model="brandForNewOrEdit.country"
              />
            </div>
            <div class="form-group col-12">
              <label for="brandShortDescription" class="float-left"
                >品牌简介</label
              >
              <input
                type="text"
                class="form-control"
                id="brandShortDescription"
                v-model="brandForNewOrEdit.short_description"
              />
            </div>
            <div class="form-group col-12">
              <label for="brandFullDescription" class="float-left"
                >品牌详细介绍</label
              >
              <textarea
                type="text"
                rows="2"
                class="form-control"
                id="brandFullDescription"
                v-model="brandForNewOrEdit.full_description"
              />
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footerButton>
        <button type="button" class="btn btn-primary" @click="saveBrandInfo">
          保存
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../Modal";
export default {
  name: "BrandList",
  components: { Modal },
  setup() {
    const store = useStore();

    // for modal
    // for add new product
    function showModalForAddNewBrand() {
      brandForNewOrEdit.value = {};
      store.commit("displayModal", {
        modalTitle: "添加新品牌",
      });
    }
    // for edit product info
    function showModalForEditBrand(id) {
      let title =
        "编辑-" + brandInfoList.value.filter((x) => x.id == id)[0].name;
      brandForNewOrEdit.value = brandInfoList.value.filter(
        (x) => x.id == id
      )[0];
      store.commit("displayModal", {
        modalTitle: title,
      });
    }

    // for load product list

    const brandInfoList = ref([]);

    async function loadBrandInfoList() {
      // loadBrandInfoList
      isNeedToShowSpinner.value = true;
      let response = await store.dispatch("loadBrandInfoList", {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
      });
      totalItemNum.value = response.data.rows.length; // response.data.totalCount;
      brandInfoList.value = response.data.rows;
      brandInfoList.value.forEach(async (e) => {
        let response = await store.dispatch("getProductNumForBrand", {
          brand_id: e.id,
        });
        e.product_count = response.data[0].count;
        console.log(response.data[0].count);
      });
      isNeedToShowSpinner.value = false;
      console.log(brandInfoList.value);
    }

    // for progress bar
    const isNeedToShowSpinner = ref(false);
    onMounted(async () => {
      isNeedToShowSpinner.value = true;
      await loadBrandInfoList();
      await loadAllSupplierList();
      // setTimeout(() => {
      //   console.log("World!");
      //   isNeedToShowProgressBar.value = false;
      // }, 2000);
      isNeedToShowSpinner.value = false;
    });

    // add or edit brand
    const brandForNewOrEdit = ref({});
    const allSupplierList = ref([]);
    // const selectedSupplier = ref({});

    async function loadAllSupplierList() {
      let response = await store.dispatch("getAllSupplierList");
      // allSupplierList.value = response.data.slice();
      response.data.forEach((element) => {
        allSupplierList.value.push(element);
      });
      console.log(response.data.slice());
      console.log(allSupplierList.value);
    }

    async function saveBrandInfo() {
      // console.log(Object.keys(selectedSupplier.value));
      // if (Object.keys(selectedSupplier.value).length === 0) {
      //   alert("请选择供应商。");
      // }
      console.log(brandForNewOrEdit.value);
      if (
        !brandForNewOrEdit.value.supplier_id ||
        brandForNewOrEdit.value.supplier_id < 1
      ) {
        alert("请选择供应商。");
        return;
      }

      if (brandForNewOrEdit.value.id && brandForNewOrEdit.value.id > 0) {
        // edit
        console.log("Edit");
        console.log(brandForNewOrEdit.value);
        store.commit("hideModal");
        await store.dispatch("updateBrandInfo", brandForNewOrEdit.value);
        loadBrandInfoList();
      } else {
        // add new
        if (
          brandForNewOrEdit.value &&
          brandForNewOrEdit.value.name &&
          brandForNewOrEdit.value.name.trim() != ""
        ) {
          console.log("Add new one");
          // console.log(supplierForNewOrEdit.value);
          await store.dispatch("addNewBrandInfo", brandForNewOrEdit.value);
          store.commit("hideModal");
          loadBrandInfoList();
        } else {
          alert("名称不能为空。");
          return;
        }
      }
    }
    // for paganation:
    const pageNum = ref(1);
    const pageSize = ref(20);
    const totalItemNum = ref(0);
    const hasPrevPage = computed(() => {
      if (pageNum.value > 1) {
        return true;
      } else {
        return false;
      }
    });
    const totalPageNum = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      return howManyPage;
    });
    const hasNextPage = computed(() => {
      let howManyPage = Math.ceil(totalItemNum.value / pageSize.value);
      if (pageNum.value >= howManyPage) {
        return false;
      } else {
        return true;
      }
    });

    function clickPageNum(clickedPageNum) {
      if (clickedPageNum != pageNum.value) {
        pageNum.value = clickedPageNum;
        loadBrandInfoList();
      }
    }

    function clickPrevPage() {
      pageNum.value--;
      loadBrandInfoList();
    }
    function clickNextPage() {
      pageNum.value++;
      loadBrandInfoList();
    }

    return {
      brandInfoList,
      showModalForAddNewBrand,
      showModalForEditBrand,
      brandForNewOrEdit,
      allSupplierList,
      // selectedSupplier,
      saveBrandInfo,
      pageNum,
      hasPrevPage,
      totalPageNum,
      hasNextPage,
      clickPageNum,
      clickPrevPage,
      clickNextPage,
      isNeedToShowSpinner,
    };
  },
};
</script>
