import { createStore } from "vuex";
import axios from "axios";
import router from "../router/index";

// const serverUrl = "http://192.168.1.226:8000";
const serverUrl = "http://47.243.33.210";

// for admin
const axiosInstanceForAdmin = axios.create({
  baseURL: serverUrl + "/api-admin/",
});

axiosInstanceForAdmin.interceptors.request.use(
  (request) => {
    // console.log("within axios request");
    let yiroolAdmin = JSON.parse(localStorage.getItem("yirool-admin"));
    if (yiroolAdmin && yiroolAdmin.token) {
      request.headers.authorization = yiroolAdmin.token;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceForAdmin.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("axios response");
    if (error.response && error.response.data.isAuth == false) {
      router.push({ name: "AdminLogin" });
    }
  }
);
// for public
const axiosInstanceForPublic = axios.create({
  baseURL: serverUrl + "/api-public/",
});

// for auth
const axiosInstanceForAuth = axios.create({
  baseURL: serverUrl + "/api-auth/",
});

export default createStore({
  state: {
    serverAddress: serverUrl,
    imageServerAddress: serverUrl + "/images/",
    // for modal
    showModal: false,
    modalTitle: "",
  },
  mutations: {
    displayModal(state, payload) {
      state.showModal = true;
      state.modalTitle = payload.modalTitle;
    },
    hideModal(state) {
      state.showModal = false;
    },
  },
  actions: {
    async loadBrandImageUrlsForPublic(context) {
      for (let i = 0; i < 12; i++) {
        let newUrl = "https://source.unsplash.com/random/120x120?sig=" + i;
      }
    },
    async loadPVInfoListForPublic(context, payload) {
      console.log(payload);
      let response = await axiosInstanceForPublic.get("get-pv-list", {
        params: payload,
      });
      console.log("response", response);
      return response;
      // for (let i = 0; i < 12; i++) {
      //   let newInfo = {
      //     imageUrl: "https://source.unsplash.com/random/120x120?sig=" + i,
      //     productName: pNames[Math.floor(Math.random() * 10)],
      //     price: Math.floor(Math.random() * 1001),
      //   };
      // }
    },
    // ================================== for admin =================================
    // for admin image
    async loadImageList(context, payload) {
      // payload demo: {pageNum:1,pageSize:20}
      // console.log(payload);
      let response = await axiosInstanceForAdmin.get("get-image-list", {
        params: payload,
      });
      console.log("loadImageList result", response);
      return response;
    },
    async uploadImageToServer(context, payload) {
      console.log("payload:", payload);
      let response = await axiosInstanceForAdmin.post("upload-image", payload);
      console.log("upload image result", response);
    },
    async deleteImageById(context, payload) {
      console.log("imageId", payload);
      let response = await axiosInstanceForAdmin.post("delete-image-by-id", {
        imageId: payload,
      });
      return response;
    },
    // for admin supplier
    async loadSupplierInfoList(context, payload) {
      // payload demo: {pageNum:1,pageSize:20}
      // console.log(payload);
      let response = await axiosInstanceForAdmin.get("get-supplier-list", {
        params: payload,
      });
      return response;
    },
    async addNewSupplierInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("add-new-supplier", {
        payload,
      });
      console.log("add-new-supplier", response);
    },
    async updateSupplierInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("update-supplier", {
        payload,
      });
      console.log("update-supplier", response);
    },

    // for admin brand area
    async loadBrandInfoList(context, payload) {
      // payload demo: {pageNum:1,pageSize:20}
      // console.log(payload);
      let response = await axiosInstanceForAdmin.get("get-brand-list", {
        params: payload,
      });
      return response;
    },
    async getAllSupplierList(context) {
      let response = await axiosInstanceForAdmin.get("get-all-supplier-list");
      return response;
    },
    async updateBrandInfo(context, payload) {
      console.log("payload:", payload);
      let response = await axiosInstanceForAdmin.post("update-brand", {
        payload,
      });
      console.log("update-brand", response);
    },
    async addNewBrandInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("add-new-brand", {
        payload,
      });
      console.log("add-new-brand", response);
    },
    async getProductNumForBrand(context, payload) {
      console.log(payload);
      let response = await axiosInstanceForAdmin.get(
        "get-product-num-for-brand",
        {
          params: payload, // need to use "params" as key when "get"
        }
      );
      return response;
    },

    // for admin product area
    async getAllBrandList(context) {
      let response = await axiosInstanceForAdmin.get("get-all-brand-list");
      return response;
    },
    async loadProductInfoList(context, payload) {
      // payload demo: {pageNum:1,pageSize:20}
      // console.log(payload);
      let response = await axiosInstanceForAdmin.get("get-product-list", {
        params: payload,
      });
      return response;
    },
    async getProductVariantNumForBrand(context, payload) {
      console.log(payload);
      let response = await axiosInstanceForAdmin.get(
        "get-product-variant-num-for-brand",
        {
          params: payload, // need to use "params" as key when "get"
        }
      );
      return response;
    },
    async updateProductInfo(context, payload) {
      console.log("payload:", payload);
      let response = await axiosInstanceForAdmin.post("update-product", {
        payload,
      });
    },
    async addNewProductInfo(context, payload) {
      console.log("addNewProductInfo");
      let response = await axiosInstanceForAdmin.post("add-new-product", {
        payload,
      });
    },

    // for admin category area
    async loadCategoryInfoList(context, payload) {
      let response = await axiosInstanceForAdmin.get("get-category-list", {
        params: payload,
      });
      return response;
    },
    async updateCategoryInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("update-category", {
        payload,
      });
    },
    async addNewCategoryInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("add-new-category", {
        payload,
      });
    },
    // for admin PV area
    async loadPVInfoList(context, payload) {
      console.log(payload);
      let response = await axiosInstanceForAdmin.get("get-pv-list", {
        params: payload,
      });
      console.log("response", response);
      return response;
    },
    async searchProductBySKUPrefix(context, payload) {
      let response = await axiosInstanceForAdmin.get(
        "search-product-by-sku-prefix",
        {
          params: payload,
        }
      );
      return response;
    },
    async loadLevelThreeCategoryList(context) {
      let response = await axiosInstanceForAdmin.get(
        "get-level-three-category-list"
      );
      return response;
    },
    async addNewPVInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("add-new-pv", {
        payload,
      });
    },
    async updatePVInfo(context, payload) {
      let response = await axiosInstanceForAdmin.post("update-pv", { payload });
    },
    // for admin auth:
    async adminLogin(context, payload) {
      console.log(payload);
      let response = await axiosInstanceForAuth.post("admin-login", {
        payload,
      });
      console.log("response", response);
      return response;
    },
  },

  modules: {},
});
