<template>
  <nav
    class="navbar navbar-main navbar-expand-lg navbar-light border-bottom d-none d-md-block"
  >
    <!-- hide when screen smaller than medium d-none d-md-block -->
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#main_nav"
        aria-controls="main_nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="main_nav">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link pl-0" data-toggle="dropdown" href="#"
              ><strong> <i class="fa fa-bars"></i>全部分类</strong></a
            >
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">男士外套</a>
              <a class="dropdown-item" href="#">女士外套</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">夹克</a>
              <a class="dropdown-item" href="#">卫衣</a>
              <a class="dropdown-item" href="#">情侣T恤</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">大牌上新</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">时尚品牌</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">新款潮牌</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">个性童装</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">上午绅士</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">商场同款</a>
          </li>
        </ul>
      </div>
      <!-- collapse .// -->
    </div>
  </nav>
</template>
